import React from 'react';

// import './NadpisPacketa.css';

const NadpisPacketa = () => (
    <h1 className='heading'>
        <span className='dark'>Zásilky z Pohody do PPL CZ API </span>
    </h1>
);


export default NadpisPacketa;
