import React, {Fragment, useEffect} from 'react'
import axios from 'axios'
import './ZasilkyList.css'
import {apiKeys} from "../../apiKeys";

function ZasilkyPacketaList() {
    const [UserData, SetUserData] = React.useState([])
    const [UserDataResult, SetUserDataResult] = React.useState([])
    const [UserNactiButton, SetUserNactiButton] = React.useState([])

    const [ZasilkyKTiskuData, SetZasilkyKTiskuData] = React.useState([])
    const [ZasilkyKTiskuDataResult, SetZasilkyKTiskuDataResult] = React.useState([])
    const [ZasilkyKTiskuButton, SetZasilkyKTiskuButton] = React.useState([])

    const [error, setError] = React.useState(null);
    // var tableRows = "<tr><td>ahoj</td></tr>";



    function buttonPdfZeZasilkova(e) {
        e.preventDefault();
        fetchPdffromPacketa();
    }

    function buttonZasilkyPacketaZPohodyKTisku(e) {
        e.preventDefault();
        // var myBlock= document.getElementById("NactiZasilkyBlock");
        SetZasilkyKTiskuData(null);
        SetZasilkyKTiskuDataResult(false);
        SetZasilkyKTiskuButton(true);

        // myBlock.remove();
        fetchZasilkyPacketaTiskuzPohody();
    }

    function buttonZasilkyPacketaNepodanezPohody(e) {
        e.preventDefault();
        // console.log("buttonZasilkyPacketaNepodanezPohody");
        // var myBlock= document.getElementById("NactiZasilkyBlock");
        SetUserData(null);
        SetUserDataResult(false);
        SetUserNactiButton(false);

        // myBlock.remove();
        fetchZasilkyPacketaNepodanezPohody();
    }

    function buttonZasilkyPacketazPohodyZapis(e) {
        e.preventDefault();
        // console.log("buttonZasilkyPacketazPohodyZapis");
        SetUserData(null);
        SetUserDataResult(false);
        SetUserNactiButton(false);

        try {
            axios.get(`${apiKeys.base}/apipohodaimege/getzasilkynepodanezpohodywritetopacketa`).then((response) => {
                SetUserDataResult(response.data.result)
                SetUserData(response.data.data);
                SetUserNactiButton(true);
                fetchZasilkyPacketaNepodanezPohody();
                fetchZasilkyPacketaTiskuzPohody();
            }).catch((error) => {
                setError(error);
                console.log(error);
            });

        } catch (error) {
            setError({message: 'Not Found'});
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const fetchPdffromPacketa = async () => {
        // console.log("fetchPdffromPacketa run");
        SetUserData(null);
        SetUserDataResult(false);
        SetUserNactiButton(false);
        SetZasilkyKTiskuData(null);
        SetZasilkyKTiskuDataResult(false);
        SetZasilkyKTiskuButton(false);

        try {
            // const response = await axios(
            //     `${apiKeys.base}/apipohodaimege/getzasilkyzpohody`
            // );
            // console.log(response);
            // const {data} = response.data
            // SetUserData(data)
            // var self= this;
            let filename;
            // {responseType:'pdf'}
            axios.get(`${apiKeys.base}/apipohodaimege/getpdffrompacketa`, {responseType: 'blob'})
                .then(response => {

                const disposition = response.headers['content-disposition'];
                filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
                if (filename.toLowerCase().startsWith("utf-8''"))
                    filename = decodeURIComponent(filename.replace("utf-8''", ''));
                else
                    filename = filename.replace(/['"]/g, '');
                return response.data;
            }).then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a); // append the element to the dom
                    a.click();
                    a.remove(); // afterwards, remove the element
                })
                .catch(error => {
                    console.error(error);
                });


        } catch (error) {
            setError({message: 'Not Found'});
        }
        // return data;
        fetchZasilkyPacketaNepodanezPohody();
        delay(5000).then(() => {fetchZasilkyPacketaTiskuzPohody() });
    }


    const fetchZasilkyPacketaTiskuzPohody = () => {

        try {
            axios.get(`${apiKeys.base}/apipohodaimege/getzasilkyktiskuzpohody`).then((response) => {
                SetZasilkyKTiskuDataResult(response.data.result)
                SetZasilkyKTiskuData(response.data.data);
                SetZasilkyKTiskuButton(true);

            }).catch((error) => {
                setError(error);
                console.log(error);
            });
        } catch (error) {
            setError({message: 'Not Found'});
        }
        // SetZasilkyKTiskuButton(true);

        // return data;
    }

    const fetchZasilkyPacketaNepodanezPohody = () => {
        try {
            axios.get(`${apiKeys.base}/apipohodaimege/getzasilkynepodanezpohody`).then((response) => {
                SetUserDataResult(response.data.result)
                SetUserData(response.data.data);
                SetUserNactiButton(true);
            }).catch((error) => {
                setError(error);
                console.log(error);
            });
        } catch (error) {
            setError({message: 'Not Found'});
        }
    }

    useEffect(() => {
        SetUserData(null);
        SetUserDataResult(false);
        SetUserNactiButton(false);

        SetZasilkyKTiskuData(null);
        SetZasilkyKTiskuDataResult(false);
        SetZasilkyKTiskuButton(false);
        fetchZasilkyPacketaNepodanezPohody();
        fetchZasilkyPacketaTiskuzPohody();


    }, [])

    if (error) return `Error: ${error.message}`;

    return (
        <Fragment>
            <div id="NactiZasilkyBlock">
                <div id="NactiZasilkyBlockNeodeslane">

                <div className="container">
                    <div className='row'>
                        {UserNactiButton ? (
                            <div className='col-auto'>
                                <form onSubmit={buttonZasilkyPacketaNepodanezPohody}>
                                    <button type='submit' className='button' onSubmit={buttonZasilkyPacketaNepodanezPohody}>
                                        Načti zásilky Zásilkovna z Pohody
                                    </button>
                                </form>
                            </div>
                        ) : (<></>)}
                    </div>
                </div>


                {UserData ? (
                    <div className="container">
                        <div className="row">
                            <h1 className="subheader">Zásilky pro Zásilkovnu po kontrole</h1>
                            <table id='tblZasilkyCheck' className="tblZasilky">
                                <thead>
                                <tr>
                                    <th>ř.</th>
                                    <th>Ověř</th>
                                    <th>Číslo</th>
                                    <th>Číslo Objednávky/VS</th>
                                    <th>Zákazník</th>
                                    <th>Email</th>
                                    <th>Telefon</th>
                                    <th>PSC</th>
                                    <th>Mesto</th>
                                    <th>Hodnota</th>
                                    <th>Dobírka</th>
                                    <th>Pobočka/Místo</th>
                                    <th>Služba</th>
                                </tr>
                                </thead>
                                <tbody>

                                {UserData.map((z) => (
                                    <Fragment key={z.number}>
                                    <tr key={z.number} >
                                        <td>{z.i}</td>
                                        <td>{z.valid}</td>
                                        <td>{z.numberOfPohoda}</td>
                                        <td>{z.number}</td>
                                        <td className="text-left text-nowrap">{z.firma}{" "}{z.surname}{" "}{z.name}
                                        </td>
                                        <td>{z.emailAddress}
                                            {/*<div className="errorNote">{z.error}</div>*/}
                                        </td>
                                        <td>{z.phoneNumber}</td>
                                        <td className="text-nowrap">{z.zip}</td>
                                        <td>{z.city}</td>
                                        <td>{z.value}</td>
                                        <td>{z.cod}</td>
                                        <td>{z.pickupPointOrCarrier}</td>
                                        <td className="text-left text-nowrap">{z.dopravceSluzba}</td>
                                    </tr>
                                        {z.error && <tr>
                                        <td className="errorNote" colSpan="13">{z.error}</td>
                                        </tr>
                                        }
                                    </Fragment>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className='col-1 col-centered'>
                            <div className="loader_block loader"></div>
                        </div>
                    </div>
                )}

                <div className="container">
                <div className='row'>
                    <div className='col-auto col-centered'>
                        <form onSubmit={buttonZasilkyPacketazPohodyZapis}>
                            {UserDataResult === "ok" ? (
                                <button type='submit' className='button button_odesli'
                                        onSubmit={buttonZasilkyPacketazPohodyZapis}>
                                    {UserDataResult} - Přepiš Ověřené do API Packeta
                                </button>
                            ) : null}
                        </form>
                    </div>
                </div>
                </div>
                </div>

                <p></p>
                <p></p>
                <p></p>

                {ZasilkyKTiskuData ? (
                    <div className="container">
                        <div className="row">
                            <h1 className="subheader">Zásilky v Zásilkově netištěné dle Pohody</h1>
                            <table id='tblZasilkyCheck' className="tblZasilky">
                                <thead>
                                <tr>
                                    <th>Poř</th>
                                    <th>Číslo Zasilky</th>
                                    <th>Číslo Objednávky/VS</th>
                                    <th>Zákazník</th>
                                    <th>Email</th>
                                    <th>Hodnota</th>
                                    <th>Dobírka</th>
                                    <th>Služba/Místo</th>
                                </tr>
                                </thead>
                                <tbody>

                                {ZasilkyKTiskuData.map((z) => (
                                    <tr key={z.number}>

                                        <td>{z.i}</td>
                                        <td>{z.cisloDopravce}</td>
                                        <td>{z.number}</td>
                                        <td className="text-left text-nowrap">{z.firma}{" "}{z.surname}{" "}{z.name}
                                            <div className="errorNote">{z.error}</div>
                                        </td>
                                        <td>{z.emailAddress}</td>
                                        <td>{z.value}</td>
                                        <td>{z.cod}</td>
                                        <td>{z.pickupPointOrCarrier}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className='col-1 col-centered'>
                            <div className="loader_block loader"></div>
                        </div>
                    </div>
                )}
                <div className="container">
                    <div className='row'>
                        {ZasilkyKTiskuButton ? (
                        <div className='col-auto'>
                            <form onSubmit={buttonZasilkyPacketaZPohodyKTisku}>
                                            <button type='submit' className='button button_pdf'
                                                    onSubmit={buttonZasilkyPacketaZPohodyKTisku}>
                                                Načti Zásilky k tisku z Pohody
                                            </button>
                            </form>
                        </div>
                        ) : (<></>)}

                        <div className='col-auto'>
                            <form onSubmit={buttonPdfZeZasilkova}>
                                {ZasilkyKTiskuDataResult === "ok" ? (
                                        <button type='submit' className='button button_pdf button_odesli'
                                                onSubmit={buttonPdfZeZasilkova}>
                                            Vytvoř PDF se štítky
                                        </button>
                                ) : null}

                                {/*<button type='submit' className='button button_odesli'*/}
                                {/*        onSubmit={buttonZasilkyPacketazPohodyZapis}>*/}
                                {/*    {UserDataResult} - Přepiš Ověřené do API Packeta*/}
                                {/*</button>*/}

                            </form>
                        </div>
                    </div>
                </div>
                {/*<div className='row'>*/}
                {/*    <div className='col-3 col-centered'>*/}
                {/*        <form onSubmit={buttonPdfZeZasilkova}>*/}
                {/*            {UserDataResult === "ok" ? (*/}
                {/*                <button type='submit' className='button button_pdf'*/}
                {/*                        onSubmit={buttonPdfZeZasilkova}>*/}
                {/*                    {UserDataResult} - PDF vygeneruj*/}
                {/*                </button>*/}
                {/*            ) : null}*/}
                {/*        </form>*/}
                {/*    </div>*/}
                {/*</div>*/}


            </div>
        </Fragment>
    )
    //

}

// <button type='submit' className='button button_odesli_podminene' onSubmit={buttonZasilkyzPohodyZapis}>
//     Pouze správné zásilky zapiš
// </button>

export default ZasilkyPacketaList
