import React, {useEffect, useState} from 'react';
import Favicon from "react-favicon";
// import React from 'react';
import NadpisPacketa from "./Components/Nadpis/NadpisPacketa";
import ZasilkyPacketaList from "./Components/ZasilkyList/ZasilkyPacketaList";
import {apiKeys} from "./apiKeys";
import ZasilkyPPLList from "./Components/ZasilkyList/ZasilkyPPLList";
import NadpisPPL from "./Components/Nadpis/NadpisPPL";

function App() {
    const faviconUrl = "favicon.ico";
    const faviconPPLUrl = "faviconPPL.ico";

    // const faviconUrl = "https://media.geeksforgeeks.org/wp-content/cdn-uploads/gfg_favicon.png";

    // const [faviconUrl, setFaviconUrl] = useState(
    //     "https://media.geeksforgeeks.org/wp-content/cdn-uploads/gfg_favicon.png"
    // );
    //
    // setFaviconUrl(
    //     (prevUrl) =>
    //         prevUrl ===
    //         "https://media.geeksforgeeks.org/wp-content/cdn-uploads/gfg_favicon.png"
    //
    //             // Change to your second favicon file
    //             ? "https://media.geeksforgeeks.org/wp-content/cdn-uploads/gfg_200X200.png"
    //
    //             // Change to your first favicon file
    //             : "https://media.geeksforgeeks.org/wp-content/cdn-uploads/gfg_favicon.png"
    // );

useEffect(() => {
        if (apiKeys.appTarget === "ppl") {
            document.title = 'PPL TopKo Zásilky';
        }
        if (apiKeys.appTarget === "packeta") {
            document.title = 'Packeta TopKo Zásilky';
        }

    }, []);

    if (apiKeys.appTarget === "ppl") {
        return (
            <div className='App'>
                <Favicon url={faviconPPLUrl} />
                <NadpisPPL/>
                <ZasilkyPPLList></ZasilkyPPLList>
                <div className="monitoring">Monitoring dkwkdlg45</div>
            </div>
        )
    }
    else {
        return (
            <div className='App'>
                <Favicon url={faviconUrl} />

                <NadpisPacketa/>
                <ZasilkyPacketaList></ZasilkyPacketaList>
                <div className="monitoring">Monitoring dkwkdlg45</div>
            </div>
        )

    }
}


export default App;
