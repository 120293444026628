export const apiKeys = {
  base: 'http://packeta.topkosmetika.pohoda.dumaservis.cz:8082',
  appTarget: "packeta",
  // base: 'http://ppl.topkosmetika.pohoda.dumaservis.cz:8083',
  // appTarget: "ppl",
  // Sevasoft Střížkov
  // base: 'http://192.168.111.108:8081',
  // base: 'http://192.168.201.108:8081',
  // txtButton: "Načti zásilky Zásilkovna z Pohody",
  // txtNadpisPPL: "Načti zásilky PPL z Pohody",
  // txtNadpis: "Načti zásilky Zásilkovna z Pohody",
  // txtNadpisPPL: "Načti zásilky PPL z Pohody",
  // base: 'http://192.168.113.108:8081',
};
// key: 'e902985907738b357b6a7c7a2651a108',
